import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'dashboard',
            component: () => import('@/layouts/Layout'),
            children: [
                // Components
                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: () => import('@/views/dashboard/dashboard'),
                },
                {
                    name: 'City',
                    path: 'pages/city',
                    component: () => import('@/views/pages/City'),
                },

                {
                    name: 'Districts',
                    path: 'districts',
                    component: () => import('@/views/pages/District'),
                },

                {
                    name: 'Schools',
                    path: 'school',
                    component: () => import('@/views/pages/Schools'),
                },

                {
                    name: 'Questions',
                    path: 'question',
                    component: () => import('@/views/pages/Question'),
                },
                {
                  name: 'Answers',
                  path: 'answers',
                  component: () => import('@/views/pages/Answers'),
              },
            ]
        },

    ],
})